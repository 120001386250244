import React from 'react'
import CTABlock from '../components/CTABlock'
import HeroSingle from '../components/HeroSingle'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'
import MassiveTitle from '../components/MassiveTitle'
import ArticleTeaser from '../components/ArticleTeaser'
import MostReadBlogPosts from '../components/MostReadBlogPosts'
import BlogSearch from '../components/BlogSearch/index'
import FuzzySearch from 'fuzzy-search'
import Sticky from 'react-sticky-el'

import { uniq, flatten, get } from 'lodash'
import SEO from '../components/SEO'
import CTABanner from '../components/CTABanner'

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.updateFilter = this.updateFilter.bind(this)

    // get all the tags from every post, purge the duplicates and sort them alphabetically
    this.tags = uniq(
      flatten(
        this.props.data.posts.edges.map(post => post.node.frontmatter.tags)
      )
    )
      .filter(tag => tag !== null)
      .sort()
  }

  state = {
    filteredData: null,
    searchQuery: '',
  }

  updateFilter({ query, tag }) {
    let result = null

    const hasQuery = query && query.length
    const hasTag = tag && tag.length && tag !== 'all'

    if (hasQuery || hasTag) {
      result = this.props.data.posts.edges
    }

    if (hasQuery) {
      const searcher = new FuzzySearch(result, ['node.frontmatter.title'], {
        caseSensitive: false,
      })

      result = searcher.search(query)
    }

    if (hasTag) {
      result = result.filter(post => {
        const postTags = get(post, 'node.frontmatter.tags', null)

        return postTags && postTags.includes(tag)
      })
    }

    this.setState({
      filteredData: result,
      searchQuery: query,
      tagFilter: tag === 'all' ? null : tag,
    })
  }

  render() {
    const { data, location } = this.props
    const { filteredData, searchQuery, tagFilter } = this.state
    const {
      frontmatter: { title },
    } = data.page

    return (
      <Layout location={location}>
        <SEO path={location.pathname} frontmatter={data.page.frontmatter} />
        <div className="bg-light pb-5">
          <section className="position-relative">
            <HeroSingle
              introTitle={'Blog'}
              title={
                filteredData ? (
                  <>
                    We found {filteredData.length}{' '}
                    {filteredData.length === 1 ? 'post' : 'posts'} with{' '}
                    {searchQuery && (
                      <>
                        {' '}
                        the keyword{' '}
                        <span className="text-primary">{searchQuery}</span>
                      </>
                    )}
                    {searchQuery && tagFilter && <> and </>}
                    {tagFilter && (
                      <>
                        {' '}
                        the category{' '}
                        <span className="text-primary">{tagFilter}</span>
                      </>
                    )}
                    <span className="text-primary">.</span>
                  </>
                ) : (
                  title
                )
              }
            />
            <CTABlock compact data={data.page.frontmatter.cta_block} />
          </section>
          <div className="u-pull-up">
            <MassiveTitle modifiers={['z0']}>Blog</MassiveTitle>
          </div>
          <div
            className="c-blog-filters position-relative"
            style={{ zIndex: 20 }}
          >
            <div className="container mb-3">
              <BlogSearch
                hasSearched={this.state.filteredData ? true : false}
                updateResults={this.updateFilter}
                tags={this.tags}
              />
            </div>
          </div>
          <section className="container position-relative c-blog-container">
            <div className="row">
              {filteredData ? (
                <>
                  {filteredData.map(post => (
                    <div
                      className="mb-3 col-12 col-md-6 col-lg-4"
                      key={post.node.id}
                    >
                      <ArticleTeaser mini data={post.node} />
                    </div>
                  ))}
                </>
              ) : (
                <>
                  <div className="col-12 col-lg-7">
                    {data.posts.edges.map(post => (
                      <div className="mb-2 mb-lg-4" key={post.node.id}>
                        <ArticleTeaser data={post.node} />
                      </div>
                    ))}
                  </div>
                  <div className="col-12 col-lg-5 col-xl-4 offset-xl-1 ">
                    <Sticky
                      className="c-sticky-bit"
                      boundaryElement=".c-blog-container"
                      style={{ position: 'relative', zIndex: 10 }}
                      hideOnBoundaryHit={false}
                      hideOnBoundaryHit={false}
                    >
                      <div className="position-sticky" style={{ top: '-1px' }}>
                        <MostReadBlogPosts data={data.mostRead} />
                      </div>
                    </Sticky>
                  </div>
                </>
              )}
            </div>
          </section>
        </div>
        <div className="bg-light c-footer">
          <CTABanner data={data.page.frontmatter.cta_banner} />
        </div>
      </Layout>
    )
  }
}

export default Index

export const SolutionsOverviewQuery = graphql`
  query {
    page: markdownRemark(frontmatter: { id: { eq: "blog" } }) {
      ...ctaBlock
      ...ctaBanner
      ...SEO
      frontmatter {
        title
      }
    }

    ...mostRead
    posts: allMdx(
      filter: { frontmatter: { type: { eq: "blog" } } }
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          excerpt
          frontmatter {
            title
            tags
            category
            tags
            date(formatString: "D MMMM YYYY")
            intro_title
            author
            author_avatar {
              childImageSharp {
                fluid(maxWidth: 150, maxHeight: 150) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            cover_image {
              childImageSharp {
                fluid(maxWidth: 1440, maxHeight: 700) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            thumbnail_image {
              childImageSharp {
                fluid(maxWidth: 642, maxHeight: 320) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
